import React from "react";
import styled from "styled-components";
import AwardItem from "./AwardItem";
import award from "../Img/Skills/medal.svg";
import heads from "../Img/Skills/commit.svg";
import github from "../Img/Skills/git.svg";

function Awards() {
  return (
    <AwardsStyled>
        <AwardItem icon={award} number={"+3"} text={"Awards"}/>
        <AwardItem icon={heads} number={"+21"} text={"Git Repos"} />
        <AwardItem icon={github} number={"+300"} text={"Commits (2021)"} />

    </AwardsStyled>
  );
}

const AwardsStyled = styled.div`
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  backdrop-filter: blur(9px);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  
  justify-content: space-around;
  @media only screen and (max-width: 375px) {
    
  }
`;

export default Awards;
