import React from "react";
import styled from "styled-components";

function Leadership() {
  return (
    <LeadershipStyled>
      <h3>Leadership Experience</h3>
      <ul className="leadershipExp">
        <li className="fontWeight400">Chief Officer of Fun | Airista</li>
        <li className="fontWeight400">
          Kidney Donor Mentor | Mount Sinai Hospital
        </li>
        <li className="fontWeight400">
          Post Bootcamp Mentor | General Assembly
        </li>
      </ul>
    </LeadershipStyled>
  );
}

const LeadershipStyled = styled.div`
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  backdrop-filter: blur(9px);
  padding: 1.5rem 1rem;
  border-radius: 15px;
  width: 58%;
  @media only screen and (max-width: 850px){
    width: 100%;
  } 
  h3 {
    padding-bottom: 1.5rem;
  }
  .leadershipExp {
    padding-left: 30px;
    ul {
    }

    ul li {
      text-indent: -2px;
      list-style-type: square;
    }

    ul li:before {
      padding-right: 5px;
    }
    .location {
      padding-left: 25px;
    }
  }
`;

export default Leadership;
