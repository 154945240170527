import React from 'react'

function PersonalSkillButton({name, number, bg}) {
    return (
        
            <div className="skill" style={{background: bg}}>
                <div className="skillsButton"><span>{number}</span> <span>{name}</span></div>
            </div>
        
    )
}

export default PersonalSkillButton;