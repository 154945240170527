import React from "react";
import styled from "styled-components";
import GitHub from "../Img/Social Media/github2.svg";
import Linkedin from "../Img/Social Media/linkedin.svg";
import Instagram from "../Img/Social Media/instagram.svg";
// import Medium from "../Img/Social Media/medium.svg";

function SocialMedia() {
  return (
    <SocialMediaStyled>
      <h3>Connect With Me</h3>
      <div className="socialContent">
        <div className="socialMedia">
          <a
            href={"https://www.linkedin.com/in/antonetteortiz/"}
            target="_blank"
            rel="noreferrer"
          >
            <img src={Linkedin} alt="" />
          </a>
        </div>

        <div className="socialMedia">
          <a
            href={"https://github.com/antonetteortiz"}
            target="_blank"
            rel="noreferrer"
          >
            <img src={GitHub} alt="" />
          </a>
        </div>

        {/* <div className="socialMedia">
          <a
            href="https://medium.com/@antonetteortiz"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Medium} alt="" />
          </a>
        </div> */}

        <div className="socialMedia">
          <a
            href={"https://www.instagram.com/xo_antonette/"}
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="" />
          </a>
        </div>
      </div>
    </SocialMediaStyled>
  );
}

const SocialMediaStyled = styled.div`
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  backdrop-filter: blur(9px);
  padding: 1.5rem 1rem;
  border-radius: 15px;
  width: 40%;
  h3 {
    padding-bottom: 1rem;
    font-weight: bold;
  }
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 15px;
  }

  @media only screen and (max-width: 375px) {
    margin-left: 0;
    margin-top: 15px;
  }

  .socialContent {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;

    .socialMedia {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 10px;
      width: 75px;
      padding-right: 10px;
      img {
        padding-right: 0px;
        width: 150%;
      }
    }
  }
`;

export default SocialMedia;
