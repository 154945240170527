import React, { useState } from "react";
import styled from "styled-components";
import Data from "../Data";
import Skills from "./Skills";
import PersonalTab from "./PersonalTab";
import WorkExperience from "./WorkExperience";
import Projects from "./Projects";
import About from "./About";

function Tabs() {
  // store Data in the state
  const [info] = useState(Data);
  const [value, setValue] = useState(0);

  const renderData = () => {
    // console.log(value)
    if (value === info[0] || value === 0 ) {
        return <Skills info={info} />
    } else if (value === info[1]) {
        return <PersonalTab />
      } else if(value === info[2]){
        return <WorkExperience />
    } else if (value === info[3]) {
      return <Projects />
    } else if (value === info[4]) {
      return <About />
    }
  } 

    return (
      <TabStyled>
        {/* Render Data in this container */}
        <div className="tabsContainer">
          <div className="buttonContainer">
            {info.map((item, index) => {
              // to display the list of buttons for the tabs
              return (
                // setting the value to whatever button the user clicks
                <button key={item.id} className={`tabsButton ${info[index] === value && "activeButton"}`} onClick={() => setValue(info[index])}>
                  {item.tab}
                </button>
              );
            })}
          </div>
          {renderData()}
        </div>
      </TabStyled>
    );
  };

const TabStyled = styled.div`
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  /* when image is in background it will blur to create glassy effect */
  backdrop-filter: blur(9px);
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.02);
  margin-bottom: 30px;
  border: 2px solid #fff;
  grid-column: 4 / span 5;
  @media only screen and (max-width: 1450px) {
  }
  .tabsContainer {
    padding: 2.5rem 2rem;
    @media only screen and (max-width: 375px) {
      padding: 1rem;
    }
    .buttonContainer {
      padding-bottom: 2rem;
      .tabsButton {
        background: transparent;
        font-family: inherit;
        font-size: inherit;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 1rem 0;
        transition: all 0.2s ease-in-out;
        opacity: 0.5;
        &:not(:last-child) {
          margin-right: 2.5rem;
          @media only screen and (max-width: 1419px) {
            margin-right: 1rem;
          }
        }
        &:hover {
          transition: all 0.2s ease-in-out;
          border-bottom: 2px solid #000;
          opacity: 1;
        }
      }
      .activeButton {
        opacity: 1;
        border-bottom: 2px solid #000;
      }
    }
  }
`;

export default Tabs;
