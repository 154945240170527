import React from 'react'
import styled from 'styled-components';

function AwardItem({icon, number, text}) {
    return (
        <AwardItemStyled>
            <img src={icon} alt="" />
            <p className="number">{number}</p>
            <p>{text}</p>
        </AwardItemStyled>
    )
}

const AwardItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0x;
  width: 100px;
  height: 130px;
  @media only screen and (max-width: 375px) {
    width: 90px;
  }
  img {
    width: 75px;
    height: auto;
    @media only screen and (max-width: 375px) {
      width: 35px;
    }
  }
  .number {
    padding: 5px 0;
    color: #6bbe92;
  }
  p {
    text-align: center;
    line-height: 1rem;
  }
  p:nth-child(2) {
    color: #f24e1e;
  }
`;

export default AwardItem;