import styled from 'styled-components';
import PersonalInfo from './Component/PersonalInfo';
import Tabs from './Component/Tabs';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, } from '@fortawesome/free-brands-svg-icons'
import { faPencilRuler, faCode, faLaptopCode, faServer, faCodeBranch, faWindowMaximize, faGlobe } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faPencilRuler, faCode, faLaptopCode, faServer, faCodeBranch, faWindowMaximize, faGlobe)

function App() {
  return (
    <AppStyled>
      <div className="portfolioContainer">
        <PersonalInfo />
        <Tabs />
      </div>
    </AppStyled>
  );
}

const AppStyled = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 5rem 5rem;
  @media only screen and (max-width: 500px) {
    padding: 1.5rem;
  }

  .portfolioContainer {
    display: grid;
    min-height: 10vh;
    width: 100%;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2rem;
    @media only screen and (max-width: 1270px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default App;
