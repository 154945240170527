import { createGlobalStyle } from "styled-components";
import Background from './Img/bg.svg';

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    
    body {
        min-height: 100vh;
        background-position: center;
        background-size: cover;
        color: #222260;
        background: url(${Background})
    }
    h4 {
        font-weight: 500;
        
    }
    p {
        line-height: 1.9rem;
    }
    li {
        font-weight: 500;
    }
    
`;

export default GlobalStyle;