import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Placeholder from "../Img/Projects/placeholder.jpg";
import Planted from "../Img/Projects/Planted.jpg";
import DraftBanking from "../Img/Projects/DraftBankingLandingPage.jpg";
import GarifunaHeroes from "../Img/Projects/GarifunaHeroesPage.jpg";
import Dictionary from "../Img/Projects/Dictionary.jpg";
import NationalParks from "../Img/Projects/NationalParks.jpg";
import Weather from "../Img/Projects/Weather.jpg";
import Javascript from "../Img/Projects/JavascriptGame.jpg";
import Yurumei from "../Img/Projects/Yurumei.jpg";
import ECommerce from "../Img/Projects/ECommerce.jpg";

function Projects() {
  return (
    <ProjectsStyled>
      <div className="projectsContainer">
        <div className="projectCard">
          <h4 className="title">Planted by Annie</h4>
          <div className="image">
            <img src={Planted} alt="landing page" />
          </div>
          <div className="projectDescription">
            <p>
              Web application featuring plants, how to care for them and
              suggestions on where to buy.
            </p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a
              href="https://plantedbyannie.vercel.app"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a
              href="https://github.com/antonetteortiz/plantedbyannie"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">Yurumei</h4>
          <div className="image">
            <img src={Yurumei} alt="" />
          </div>
          <div className="projectDescription">
            <p>
              Web application featuring all things Garifuna - music, food,
              history, music and more.
            </p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a href="https://yurumei.org" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a
              href="https://github.com/antonetteortiz/yurumei"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">Weather App</h4>
          <div className="image">
            <img src={Weather} alt="" />
          </div>
          <div className="projectDescription">
            <p>Weather web application.</p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a
              href="https://weather-app-mocha-seven.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/weather-app">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">National Parks</h4>
          <div className="image">
            <img src={NationalParks} alt="" />
          </div>
          <div className="projectDescription">
            <p>
              Using react router and the Nationl Parks API, this web application
              displays all the national parks located in United States of
              America.
            </p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a
              href="https://nationalparks.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/nationalparks">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">Dictionary</h4>
          <div className="image">
            <img src={Dictionary} alt="" />
          </div>
          <div className="projectDescription">
            <p>Dictionary web application built using the dictionary API.</p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a
              href="https://dictionary-one.vercel.app"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/dictionary">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">Garifuna Leaders</h4>
          <div className="image">
            <img src={GarifunaHeroes} alt="" />
          </div>
          <div className="projectDescription">
            <p>Library of Garifuna leaders, past and present.</p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
          </div>
          <div className="buttons">
            <a
              href="https://garifunaheroes.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/garifunaleaders">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">Javascript Flashcard Game</h4>
          <div className="image">
            <img src={Javascript} alt="" />
          </div>
          <div className="projectDescription">
            <p>
              Javascript flashcard game to help user study terms in javascript.
            </p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
          </div>
          <div className="buttons">
            <a
              href="https://javascript-flashcards.vercel.app"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/javascript-flashcards">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">Draft Banking</h4>
          <div className="image">
            <img src={DraftBanking} alt="" />
          </div>
          <div className="projectDescription">
            <p>Landing page for Draft Banking.</p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a
              href="https://draftbanking.vercel.app"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/draftbanking">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>

        <div className="projectCard">
          <h4 className="title">ECommerce</h4>
          <div className="image">
            <img src={ECommerce} alt="" />
          </div>
          <div className="projectDescription">
            <p>ECommerce landing page built using HTML & CSS.</p>
          </div>
          <div className="techHeading">
            <h5>Technology Used</h5>
          </div>
          <div className="technology">
            <span className="html">HTML</span>
            <span className="css">CSS</span>
            <span className="javascript">Javascript</span>
            <span className="react">React</span>
          </div>
          <div className="buttons">
            <a
              href="https://ecommerce-project-one.vercel.app"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon="globe" className="icon" />
            </a>
          </div>
          <div className="buttons">
            <a href="https://github.com/antonetteortiz/draftbanking">
              <FontAwesomeIcon icon="code-branch" className="icon" />
            </a>
          </div>
        </div>
      </div>
    </ProjectsStyled>
  );
}

const ProjectsStyled = styled.div`
  .projectsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 20px;
    text-align: center;
    padding-bottom: 20px;
    @media screen and (max-width: 1250px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media only screen and (max-width: 375px) {
      /* grid-template-columns: repeat(1, 1fr); */
      /* padding: 1rem; */
      /* width: 100%; */
    }
    .projectCard {
      background: linear-gradient(
        117.76deg,
        rgba(255, 255, 255, 0.7) -7.21%,
        rgba(255, 255, 255, 0.5) 118.08%
      );
      backdrop-filter: blur(9px);
      padding: 1.5rem 1rem;
      border-radius: 15px;
      grid-gap: 30px;
      @media only screen and (max-width: 375px) {
        grid-gap: 0px;
      }
      h5 {
        font-size: 1rem;
      }
      img {
        width: 450px;
        height: 300px;
        object-fit: cover;
        border-radius: 5px;
        @media only screen and (max-width: 1800px) {
          width: 400px;
          height: 250px;
        }
        @media only screen and (max-width: 500px) {
          width: 225px;
          height: 150px;
        }
      }
      p {
        padding-bottom: 1rem;
        line-height: 1.5rem;
        @media only screen and (max-width: 500px) {
          font-size: 15px;
        }
      }
      .title {
        padding: 10px 0;
      }
      .projectDescription {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        align-items: center;
        justify-content: center;
        height: 75px;
      }
      .techHeading {
        padding: 10px 0;
      }
      .technology {
        padding-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .buttons {
        font-size: 35px;
        margin-right: 10px;
        display: inline-flex;
        a {
          text-decoration: none;
        }
        .icon {
          font-size: 35px;
          color: #573280;
        }
      }
      .html {
        padding: 0px 10px;
        margin: 10px 10px;
        background-color: #ffa100;
        border-radius: 15px;
      }
      .css {
        padding: 0px 10px;
        margin: 10px 10px;
        background-color: #1fde78;
        border-radius: 15px;
      }
      .javascript {
        padding: 0px 10px;
        margin: 10px 10px;
        background-color: #de1f85;
        border-radius: 15px;
      }
      .react {
        padding: 0px 10px;
        margin: 10px 10px;
        background-color: #1fded8;
        border-radius: 15px;
      }
    }
  }
`;

export default Projects;
