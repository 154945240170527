import React from "react";
import styled from "styled-components";

function WorkExperience() {
  return (
    <WorkExperienceStyled>
      <div className="workExperienceContainer">
        <h4>Work Experience</h4>
        <ul className="Timeline">
          {/* job one */}
          <li className="TimelineItem">
            <div className="TimelineLeft">
              <p>February 2022 - October 2023</p>
            </div>
            <div className="TimelineRight">
              <div className="TimeLineContent">
                <div className="jobDescription">
              <h5><b>Helium Foot Software</b></h5>
                  <h6>
                    SOFTWARE DEVELOPER
                  </h6>
                  <p>Skills: React, Typescript, Javascript, Nodejs, MySQL, Express, AWS, Ant Design, Figma </p>
                  <li>
                    Enhanced website functionality by implementing CRUD operations, enabling seamless scheduling and real-time messaging, resulting in a 30% improvement in user satisfaction and a 25% increase in user retention.
                  </li>
                  <li>
                    Introduced e-commerce capabilities within the portal integrating with Stripe, leading to increased revenue generation across 3,000 swim clubs.
                  </li>
                  <li>
                    Enhanced software performance by debugging issues in a live staging environment before releasing to production, resulting in an 85% improvement in client callouts.
                  </li>
                  <li>
                    Conducted the successful deployment of new features on a rolling basis per the release schedule, resulting in a 25% reduction in bug reports and a 30% increase in customer acquisition.
                  </li>
                  <li>
                    Deployed cloud-based solutions utilizing AWS to meet client's application and infrastructure requirements. Conducted testing and deployment of new AWS services while also generating essential technical documentation.
                  </li>
                  <li>
                    Served as a backup contact for specific stakeholders, including resolving problems or issues on an as-needed basis.
                  </li>
                  <li>
                    Participated in software analysis, design, development, and implementation to ensure software met business needs.
                  </li>
                </div>
              </div>
            </div>
          </li>
          {/* job two */}
          <li className="TimelineItem">
            <div className="TimelineLeft">
              <p>March 2021 - January 2022</p>
            </div>
            <div className="TimelineRight">
              <div className="TimeLineContent">
                <div className="jobDescription">
              <h5><b>Airista</b></h5>
                  <h6>
                    SOFTWARE DEVELOPER
                  </h6>
                  <p>Skills: Angular, Typescript, Javascript, Jira, Confluence, Express, SQL</p>
                  <li>
                    Streamlined legacy codebases by implementing Angular and TypeScript, resulting in a 40% reduction in development time and improved code maintainability.
                  </li>
                  <li>
                    Planned the coordination of release schedules, documented versions, and distributed comprehensive release notes, resulting in an 85% reduction in callouts and meetings, streamlining cross-functional collaboration, and maximizing productivity.
                  </li>
                  <li>
                    Led the implementation of a company-wide software development lifecycle, boosting productivity by 94%. Conducted meetings with cross-functional teams to plan iteration, gather user requirements, discuss software design, and prepare any technical documentation needed to provide to clients.
                  </li>
                  <li>
                    Partnered with cross-functional teams to support product development, identify prototype programs, and explore new business opportunities.
                  </li>
                  <li>
                    Spearheaded team development initiatives as Chief Officer of Fun, curating engaging events and activities that fostered stronger working relationships; resulting in a 15% boost in overall employee satisfaction.
                  </li>
                </div>
              </div>
            </div>
          </li>
          {/* job three */}
          <li className="TimelineItem">
            <div className="TimelineLeft">
              <p>September 2020 - December 2020</p>
            </div>
            <div className="TimelineRight">
              <div className="TimeLineContent">
                <div className="jobDescription">
              <h5><b>General Assembly</b></h5>
                  <h6>Software Developer</h6>
                  <p>Skills: HTML, CSS, Javascript, PostgreSQL, MongoDB, NodeJS, Reactjs, Express, Figma</p>
                  <li>
                    Full-stack web development program providing experience with
                    the latest front- and back-end programming languages, tools,
                    and methodologies including HTML, CSS, Javascript,
                    PostgreSQL, MongoDB, NodeJS, ReactJS, Git, Github,
                    wire-framing, user stories/requirements planning, and
                    test-driven development.
                  </li>
                  <p className="title">Projects</p>
                  <div className="projects">
                  <p><u>Yurumei:</u> Full-stack MERN (Mongoose, Express, React & Nodejs) application created for all things related to the Garifuna culture: history, music, and food just to name a few.</p>
                  <p><u>React Component Library:</u> Built a variety of reusable stateful and functional components using React from an Adobe XD document and made them all accessible through astorybook library.</p>
                  <p><u>Garifuna Music Library:</u> Created a full-stack application using MERN (Mongoose, Express, React & Nodejs) stack to exposeancient Garifuna music.</p>
                  <p><u>Draft Bank:</u> Built a landing page using HTML & CSS.</p>
                  </div>
                  
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </WorkExperienceStyled>
  );
}

const WorkExperienceStyled = styled.div`
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  backdrop-filter: blur(9px);
  padding: 1.5rem 1rem;
  border-radius: 15px;
  h6 {
    font-size: 1.2rem;
  }
  h5 {
    font-weight: 500;
    font-size: 1.5rem;
    @media only screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }
  .TimelineItem {
    display: grid;
    grid-template-columns: 25% 75%;
    li {
      padding-bottom: 1rem;
      line-height: 1.5rem;
    }
    .title {
      font-weight: bold;
    }
    .TimelineLeft{
      padding-right: 1rem;
    }
    .TimelineRight {
      padding-left: 2rem;
      border-left: 2px solid #6bbe92;
      position: relative;
      .jobDescription {
        padding-bottom: 3rem;
        .projects {
          padding-left: 5%;
          p {
            padding: 5px 0;
            line-height: 1.5rem;
            @media only screen and (max-width: 375px){
              font-size: 12px;
            }
          }
        }
      }
      h6::before {
        font-size:35px;
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 4px;
        border: 2px solid #f56692;
        left: -10px;
        top: 5px;
      }
    }
  }
`;

export default WorkExperience;
