import React from "react";
import styled from "styled-components";
import Awards from "./Awards";
import Leadership from "./Leadership";
import PersonalSkillButton from "./PersonalSkillButton";
import SocialMedia from "./SocialMedia";

function PersonalTab() {
  return (
    <PersonalTabStyled>
      <div className="skillCon">
        <h3>Personal Skills</h3>
        <PersonalSkillButton name={"CREATIVE"} number={"1."} bg={"#6BBE92"} />
        <PersonalSkillButton
          name={"PROBLEM SOLVER"}
          number={"2."}
          bg={"#F44E77"}
        />
        <PersonalSkillButton
          name={"FAST LEARNER"}
          number={"3."}
          bg={"#16194F"}
        />
        <PersonalSkillButton
          name={"TEAM PLAYER"}
          number={"4."}
          bg={"#554DDE"}
        />
        <PersonalSkillButton
          name={"PROFESSIONAL"}
          number={"5."}
          bg={"#9A94FF"}
        />
      </div>
      <div className="awardsContainer">
        <Awards />
        <div className="socialContainer">
          <Leadership />
          <SocialMedia />
        </div>
      </div>
    </PersonalTabStyled>
  );
}

const PersonalTabStyled = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 15px;
  @media only screen and (max-width: 920px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  .skillCon {
    background: linear-gradient(
      117.76deg,
      rgba(255, 255, 255, 0.7) -7.21%,
      rgba(255, 255, 255, 0.5) 118.08%
    );
    /* when image is in background it will blur to create glassy effect */
    backdrop-filter: blur(9px);
    padding: 2.5rem 2rem;
    border-radius: 15px;
    @media only screen and (max-width: 375px) {
      padding: 1rem;
    }
    .skill {
      padding: 0.8rem 1rem;
      color: white;
      border-radius: 15px;
      margin: 1.4rem 0;

      .skillsButton {
        display: grid;
        grid-template-columns: 20px 200px;
        span:first-child {
          width: 10%;
        }
      }
    }
  }

  .awardsContainer {
    .socialContainer {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media only screen and (max-width: 375px) {
        flex-direction: column;
      }
    }
  }
`;

export default PersonalTab;
