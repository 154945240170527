import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Skills() {
  return (
    <SkillsStyled>
      <div className="container">
        <div className="skillContainer">
          <h2>What I Can Do</h2>
        </div>
        <div className="mySkills">
          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon="pencil-ruler" className="icon" />
            </div>
            <h5>Web Design</h5>
            <p>
              I value simple content structure, clean design patterns and
              thoughtful interactions.
            </p>
          </div>

      
          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon="laptop-code" className="icon" />
            </div>
            <h5>Web Dev</h5>
            <p>
              I enjoy coding things from scratch,bringing ideas to
              life  and exercising my creative muscle.
            </p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon="server" className="icon" />
            </div>
            <h5>Back-end Dev</h5>
            <p>
              I like to create simple yet elegant solutions for the backend,
              Node.Js is the language I speak.
            </p>
          </div>
        </div>

        <div className="skillContainer">
          <h2>Technologies</h2>
        </div>
        <div className="webTools">
          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "html5"]} className="icon" />
            </div>
            <p className="title">HTML</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "css3-alt"]} className="icon" />
            </div>
            <p className="title">CSS</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "js-square"]} className="icon" />
            </div>
            <p className="title">JAVASCRIPT</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "python"]} className="icon" />
            </div>
            <p className="title">PYTHON</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "sass"]} className="icon" />
            </div>
            <p className="title">SASS</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "react"]} className="icon" />
            </div>
            <p className="title">REACT</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "angular"]} className="icon" />
            </div>
            <p className="title">ANGULAR</p>
          </div>

          <div className="skill">
            <div className="iconContainer">
              <FontAwesomeIcon icon={["fab", "node-js"]} className="icon" />
            </div>
            <p className="title">NODEjs</p>
          </div>
        </div>
      </div>
    </SkillsStyled>
  );
}

const SkillsStyled = styled.div`
  .container {
    display: grid;
    grid-gap: 20px;

    .mySkills {
      background: linear-gradient(
        117.76deg,
        rgba(255, 255, 255, 0.7) -7.21%,
        rgba(255, 255, 255, 0.5) 118.08%
      );
      backdrop-filter: blur(9px);
      padding: 1.5rem 1rem;
      border-radius: 15px;
      width: 100%;
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
      grid-gap: 20px;
      text-align: center;
      padding-bottom: 20px;

      @media screen and (max-width: 1250px) {
        display: flex;
      }
    }

    .skill {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
      .iconContainer {
        width: 60px;
        height: 50px;
        color: #441f87;
        padding: 2px;
        .icon {
          font-size: 45px;
        }
      }
      @media screen and (max-width: 1250px) {
      }
    }

    .skill h5 {
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-top: 20px;
      font-size: 20px;
      padding-bottom: 15px;
    }

    .skill p {
      line-height: normal;
      text-align: center;
    }
    .webTools {
      background: linear-gradient(
        117.76deg,
        rgba(255, 255, 255, 0.7) -7.21%,
        rgba(255, 255, 255, 0.5) 118.08%
      );
      backdrop-filter: blur(9px);
      padding: 1.5rem 1rem;
      border-radius: 15px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
      grid-gap: 20px;
      text-align: center;
      padding-top: 30px;
      .title {
        padding-top: 10px;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        color: inherit;
        letter-spacing: 1px;
      }
    }
  }
`;

export default Skills;
