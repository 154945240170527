import React from "react";
import styled from "styled-components";
import PortfolioPic from "../Img/portfoliopic.png";

function About() {
  return (
    <AboutStyled>
      <div className="aboutMeContainer">
        <div className="profilePicture">
          <div className="picture">
            <img src={PortfolioPic} alt="profilePicture" />
          </div>
          <div className="rightSection">
            <p className="aboutMe">
              Recently graduated from General Assembly Software Immersive boot
              camp, I fell in love with computer programming. As a developer,
              what drew me in was the huge impact that can be made on people and
              society through creating web applications. The challenges are also
              fascinating, with the growth of technology there is always
              something new to learn or a more efficient way of coding.
              <br />
              <br />I enjoy the process of getting better and building on what I
              have learned at boot camp and I am excited to continue building on
              what I have learned. I am a quick learner with an eye for detail
              and a passion for design. I would be thrilled to work in a
              collaborative environment to develop innovative solutions and use
              the skills I have learned.
            </p>
          </div>
        </div>
        <p className="funFacts">
          <p>
            I was born and raised in a small town in Dangriga, Belize. Ever
            since I was a kid, building things from coffee tables to now web
            applications has always brought me so much joy. I've always been
            fascinated with how things work and got in trouble plenty of times
            for taking things apart. It's no surprise that I have ended up in
            this career field.
          </p>
        </p>
      </div>
    </AboutStyled>
  );
}

const AboutStyled = styled.div`
  grid-column: 1 /4;
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  /* when image is in background it will blur to create glassy effect */
  backdrop-filter: blur(9px);
  border-radius: 8px;
  margin-bottom: 30px;
  border: 2px solid #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.02);

  /* Personal Info */
  .aboutMeContainer {
    padding: 2.5rem 2rem;
    .profilePicture {
      display: grid;
      grid-template-columns: repeat(25rem, 1fr);
      grid-gap: 20px;
      .picture {
        width: 100%;
        border-radius: 10px;
        display: block;
        margin: auto;
        img {
          width: 100%;
          display: block;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .rightSection {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        p {
          padding: 1rem 0;
        }
      }
    }
    .aboutMe {
      padding: 1.5rem 0;
    }
    .funFacts {
      padding: 1.5rem 0;
    }
    .iconText {
      .icons {
        .icon {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          p {
            padding-left: 1rem;
          }
        }
      }
    }
  }
`;

export default About;
