import React from "react";
import styled from "styled-components";
import profile from "../Img/ao.svg";
import Location from "../Img/location.svg";
import Email from "../Img/email.svg";
import GitHub from "../Img/Social Media/git.svg";
import Resume from "../Img/AntonetteOrtizResume.pdf";


function PersonalInfo() {
  return (
    <PersonalInfoStyled>
      <div className="personalInfoContainer">
        <div className="profilePicture">
          <div className="picture">
            <img src={profile} alt="profilePicture" />
          </div>
          <div className="rightSection">
            <h1 className='name'>Antonette Ortiz</h1>
            <div>
              <p>Web Developer</p>
              <p>Software Engineer</p>
              <p>Chief Officer of Fun</p>
            </div>
            <div className="contactButton">
              <a href={Resume} target="_blank" rel="noreferrer">
                Download My Resume
              </a>
            </div>
          </div>
        </div>
        <p className="aboutMe">
          Hi there! I am a Washington DC bases Software Engineer. Passionate
          about creating beautiful web applications with extensive knowledge in
          multiple front-end and back-end technologies. Highly motivated and
          self-driven individual.
        </p>
        <div className="iconText">
          <div className="icons">
            <div className="icon">
              <img src={Location} alt="" />
              <p>Washington, DC</p>
            </div>
            <div className="icon">
              <a href={"https://github.com/antonetteortiz"} target="_blank" rel="noreferrer">
                <img src={GitHub} alt="" />
              </a>
              <a href={"https://github.com/antonetteortiz"} target="_blank" rel="noreferrer">
                <p>AntonetteOrtiz</p>
              </a>
            </div>
            <div className="icon">
              <a href="mailto: ortizantonette@gmail.com" rel="noreferrer">
              <img src={Email} alt="" />
              </a>
              <a href="mailto: ortizantonette@gmail.com">
              <p>Email me!</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </PersonalInfoStyled>
  );
}

const PersonalInfoStyled = styled.div`
  grid-column: 1 /4;
  background: linear-gradient(
    117.76deg,
    rgba(255, 255, 255, 0.7) -7.21%,
    rgba(255, 255, 255, 0.5) 118.08%
  );
  /* when image is in background it will blur to create glassy effect */
  backdrop-filter: blur(9px);
  border-radius: 8px;
  margin-bottom: 30px;
  border: 2px solid #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.02);
  @media only screen and (max-width: 375px) {
    display: flex;
  }
  /* Personal Info */
  .personalInfoContainer {
    padding: 2.5rem 2rem;
    @media only screen and (max-width: 375px) {
      padding: 1rem;
    }
    .profilePicture {
      @media only screen and (max-width: 920px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: 740px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }
      .picture {
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .rightSection {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        @media only screen and (max-width: 799px) {
          .name {
            font-size: 30px;
          }
          p {
            font-size: 15px;
          }
        }
      }
      .contactButton {
        background-color: #6bbe92;
        color: #fff;
        padding: 0.6rem 0.9rem;
        text-align: center;
        border-radius: 7px;
        margin-top: 10px;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
    .aboutMe {
      padding: 20px 20px;
    }
    .iconText {
      .icons {
        a {
          text-decoration: none;
        }
        .icon {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          a {
            text-decoration: none;
          }
          p {
            padding-left: 1rem;
          }
        }
      }
    }
  }
`;

export default PersonalInfo;
