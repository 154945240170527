import css from './Img/Skills/css.svg'
import html from './Img/Skills/html.svg'
import javascript from './Img/Skills/js.svg'
import react from './Img/Skills/react-js.svg'
import mongodb from './Img/Skills/mongodb.svg'
import node from './Img/Skills/node-js.svg'
import angular from './Img/Skills/angular.svg'
import typescript from './Img/Skills/typescript.svg'


const data = [
    {
        id: 1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis viverra viverra mollis sed vitae fames nunc sollicitudin viverra. Curabitur massa, ultrices diam ipsum faucibus risus. Hendrerit justo, quis massa a elementum. At elementum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis viverra viverra mollis sed vitae fames nunc sollicitudin viverra. Curabitur massa, ultrices diam ipsum faucibus risus. Hendrerit justo, quis massa a elementum. At elementum.',
        skills: ['CSS', 'HTML', 'Javascript', 'React', 'MongoDB', 'NodeJs', 'Angular', 'Typescript'],
        skillImages: [css, html, javascript, react, mongodb, node, angular, typescript],
        tab: 'My Skills'
    },
    {
        id: 2,
        skills: ['Creative Design', 'Management', 'Organized', 'Fast Learner', 'Team Player'],
        bgColours: ['#F44E77', '#554DDE'],
        awards: ['badge', 'coding', 'experience'],
        awardsText: ['10+', '15+', '69+'],
        languages: ['English', 'Garifuna'],
        social: ['linkedin', 'dev'],
        tab: 'Personal Information'
        
    },
    {
        id: 3,
        about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis mattis nam ipsum pharetra porttitor eu orci, nisi. Magnis elementum vitae eu, dui et. Tempus etiam feugiat sem augue sed sed. Tristique feugiat mi feugiat integer consectetur sit enim penatibus. Quis sagittis proin fermentum tempus uspendisse ultricies. Tellus sapien, convallis proin pretium.',
        tab: 'Experience'
    },
    {
        id: 4,
        about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis mattis nam ipsum pharetra porttitor eu orci, nisi. Magnis elementum vitae eu, dui et. Tempus etiam feugiat sem augue sed sed. Tristique feugiat mi feugiat integer consectetur sit enim penatibus. Quis sagittis proin fermentum tempus uspendisse ultricies. Tellus sapien, convallis proin pretium.',
        tab: 'Projects'
    },
    {
        id: 5,
        about: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis mattis nam ipsum pharetra porttitor eu orci, nisi. Magnis elementum vitae eu, dui et. Tempus etiam feugiat sem augue sed sed. Tristique feugiat mi feugiat integer consectetur sit enim penatibus. Quis sagittis proin fermentum tempus uspendisse ultricies. Tellus sapien, convallis proin pretium.`,
        tab: 'Behind the Keyboard'
    }
]

export default data;